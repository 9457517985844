import { useContext, useRef, useMemo } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { navigate } from 'gatsby';
import { useTranslation } from './useTranslation';
import useFormState from './useFormState';
import validationRules, {
  Model,
} from '../components/forgot-password-form/ForgotPasswordForm.rules';
import { forgotPasswordMutation } from '../queries/forgotPassword.query';
import useAuth from './useAuth';
import { parsePhoneNumber } from '../util/format';
import ROUTES from '../routes';
import {
  MutationValidateAndUpdateTempPasswordArgs,
  ForgotPasswordResponse,
  PersonalIdentificationType,
  ForgotPasswordErrorType,
} from '../__generated__/pge-types';
import { TransitionContext } from '../providers/TransitionProvider';
import { NotificationsContext } from '../providers/NotificationsProvider';
import { getFromStorage } from '../util/storage-utils';
import { useLocation } from '@reach/router';
import { EMAIL_REGEX, PHONE_REGEX } from '../util/form-validation';

export const defaultFormValues: Model = {
  ssn: '',
  driversLicense: '',
  pin: '',
  dob: '',
  ein: '',
  itin: '',
  identificationType: 'ssn',
  userIdType: 'email',
  emailPhone: '',
};

export const ForgotPasswordErrors = Object.freeze({
  AccountLocked: 'AccountLocked',
});

type RouterState = {
  redirectUrl?: string;
};

export default function useForgotPasswordForm() {
  const { signInWithCustomToken } = useAuth();
  const notificationContext = useContext(NotificationsContext);

  const location = useLocation();
  const redirectUrl =
    ((location.state || {}) as RouterState).redirectUrl || undefined;

  const defaultEmail = useMemo(() => getFromStorage('userEmail') || '', []);

  const form = useFormState(
    {
      ...defaultFormValues,
      emailPhone: defaultEmail,
    },
    { validate: validationRules },
  );
  const { setState: updateTransition } = useContext(TransitionContext);
  const errorCount = useRef(0);
  const { t, richT } = useTranslation();

  const HandleErrorCount = () => {
    errorCount.current += 1;
    if (errorCount.current > 2) {
      return navigate(ROUTES.FORGOT_PASSWORD_SPECIAL_HANDLING);
    }
  };
  const HandleError = (errorMessage: any) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    HandleErrorCount();
    notificationContext.setState({
      isOpen: true,
      message: errorMessage,
      severity: 'error',
    });
    typeof window !== 'undefined' && window.scrollTo(0, 0);
  };

  const onError = (error: ApolloError) => {
    console.error('forgot password form error', error);
    updateTransition({ open: false });
    if (error?.message?.length) {
      if (
        error.message
          .split('GraphQL error: ')
          ?.join('')
          ?.trim() === ForgotPasswordErrors.AccountLocked
      ) {
        HandleError(
          richT('PASSWORD_ATTEMPTS_EXCEEDED_DAILY_LIMIT', {
            CUSTOMER_SERVICE_NUMBER: `tel:${t('CALL_FOR_ASSISTANCE_NUMBER')}`,
          }),
        );
      } else {
        HandleError(t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'));
      }
    }
  };

  const onSuccess = async (
    signInToken: string,
    forgotPasswordError: ForgotPasswordErrorType,
  ) => {
    updateTransition({ open: false });
    if (forgotPasswordError === ForgotPasswordErrorType.None) {
      await signInWithCustomToken(signInToken);
      return navigate(ROUTES.CHANGE_PASSWORD, {
        replace: true,
        state: {
          redirectUrl,
        },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      HandleErrorCount();
      switch (forgotPasswordError) {
        case ForgotPasswordErrorType.NoPersonFound:
          form.setError('emailPhone', t('PERSONAL_ID_NO_MATCH'));
          break;
        case ForgotPasswordErrorType.NoPhoneFound:
          form.setError(
            'emailPhone',
            t('FORGOT_PASSWORD_ERROR_MESSAGE_PHONE_NUMBER_NOT_FOUND'),
          );
          break;
        case ForgotPasswordErrorType.NoValidWebUser:
          form.setError(
            'emailPhone',
            t('FORGOT_PASSWORD_ENTER_REGISTERED_EMAIL_ADDRESS'),
          );
          break;
        case ForgotPasswordErrorType.SpecialHandling:
          return navigate(ROUTES.FORGOT_PASSWORD_SPECIAL_HANDLING);

        default:
          HandleError(t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'));
      }
    }
  };

  const getPersonalIdentificationType = (
    identificationType: any,
  ): PersonalIdentificationType => {
    switch (identificationType) {
      case 'ssn':
        return PersonalIdentificationType.Ssn;
      case 'driversLicense':
        return PersonalIdentificationType.Dl;
      case 'pin':
        return PersonalIdentificationType.Pincode;
      case 'ein':
        return PersonalIdentificationType.Ein;
      case 'dob':
        return PersonalIdentificationType.Dob;
      case 'itin':
        return PersonalIdentificationType.Itin;
    }
    return PersonalIdentificationType.None;
  };

  const [validateAndUpdateTempPassword] = useMutation<
    { validateAndUpdateTempPassword: ForgotPasswordResponse },
    MutationValidateAndUpdateTempPasswordArgs
  >(forgotPasswordMutation, {
    variables: {
      payload: {
        emailAddress:
        form.values.userIdType === 'email' ? form.values.emailPhone.toLowerCase() : '',
        phoneNumber:
          form.values.userIdType === 'phone'
            ? parsePhoneNumber(form.values.emailPhone)
            : '',
        lastFourDigitSSN:
          form.values.identificationType === 'ssn' ? form.values.ssn : '',
        lastFourDigitStateOrDriverID:
          form.values.identificationType === 'driversLicense'
            ? form.values.driversLicense
            : '',
        pinCode:
          form.values.identificationType === 'pin' ? form.values.pin : '',
        birthDate:
          form.values.identificationType === 'dob' ? form.values.dob : '',
        lastFourDigitEIN:
          form.values.identificationType === 'ein' ? form.values.ein : '',
        lastFourDigitITIN:
          form.values.identificationType === 'itin' ? form.values.itin : '',
        identificationType: getPersonalIdentificationType(
          form.values.identificationType,
        ),
      },
    },
    errorPolicy: 'all',
    onError,
    onCompleted: response => {
      console.info(
        'forgot password :validateAndUpdateTempPassword Api Response',
        response,
      );
      if (response?.validateAndUpdateTempPassword) {
        const {
          signInToken,
          forgotPasswordError,
        } = response.validateAndUpdateTempPassword;
        return onSuccess(signInToken!, forgotPasswordError!);
      }
    },
  });

  const handleSubmit = form.submit(
    async () => {
      // Check if the input matches the email pattern
      if (EMAIL_REGEX.test(form.values.emailPhone)) {
        void form.setValue('userIdType', 'email');
      } else if (PHONE_REGEX.test(form.values.emailPhone)) {
        // Check if the input matches the phone number pattern
        void form.setValue('userIdType', 'phone');
      }
      updateTransition({ open: true });

      await validateAndUpdateTempPassword();
    },
    errors => {
      console.log(errors);
    },
  );

  return {
    ...form,
    handleSubmit,
    redirectUrl,
  };
}
